.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input[type="text"] {
  width: calc(100% - 26px);
  padding: 13px;
  border: 1px solid #cacaca;
  font-size: 18px;
  border-radius: 10px;
  outline: 1px solid;
  outline: none;
  transition: all 0.3s ease;
}

input[type="text"]:focus {
  border: 1px solid #5f30e2;
  background-color: #f6f1ff;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #797979;
  outline: none;
  transition: border 0.24s ease-in-out;
  max-height: 100px;
  text-align: center;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

video {
  width: 100%;
  height: 300px;
  background-color: black;
}
