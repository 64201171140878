.container {
    display: grid;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .container .left {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
      url("https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=564&q=80");
    background-size: cover;
    background-position: center center;
  }

  .app-logo {
    position: fixed;
    height: 20px;
    padding: 60px 40px;
  }

  .right-scroll {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .panes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    transition: transform 0.5s ease;
  }

  .pane {
    width: 100%;
    height: 100vh;
    flex-shrink: 0;
    display: grid;
    justify-content: center;
    grid-template-columns: calc(100% - 160px);
  }

  @media only screen and (max-width: 1110px) {
    .container {
      grid-template-columns: 1fr;
      grid-template-rows: 0px minmax(0, 3fr);
    }

    .pane {
      grid-template-columns: calc(100% - 80px);
    }

    .container .left {
      width: 100%;
      background-position: center 8%;
    }

    .app-logo {
      height: 20px;
      padding: 30px;
      filter: brightness(0);
      z-index: 1000;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0;
  }

  .content h2 {
    font-size: 32px;
    text-align: left;
  }

  .content h4 {
    font-size: 24px;
    font-weight: 500;
    text-align: left;
  }

  .content p.sub-title {
    font-size: 18px;
    color: #777777;
  }

  .content p.sub-title-small {
    font-size: 16px;
    color: #777777;
  }

  p.label {
    font-size: 18px;
    font-weight: 500;
  }

  .progress-container {
    border: 1px solid #f6f1ff;
    width: 100%;
    height: 30px;
    display: block;
    margin-top: 5px;
    border-radius: 100px;
    background-color: #f6f1ff;
    position: relative;
  }

  .progress {
    background-color: #5f30e2;
    height: 30px;
    border-radius: 100px;
  }

  .video-recorder.hidden {
    height: 0px;
    visibility: hidden;
  }
