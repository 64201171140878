@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.02em;
}

button {
  background-color: #5f30e2;
  border: none;
  padding: 15px 25px;
  border-radius: 12px;
  color: white;
  letter-spacing: 0.02em;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  font-weight: 600;
}

button.back {
  background-color: transparent;
  color: #777777;
}

button.inline {
  background-color: transparent;
  color: #5f30e2;
  padding: 15px 0;
}

button.inline.red {
  color: #e70000;
}

button:disabled {
  background-color: #777777;
}

.preview-container {
  position: relative;
}

.preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
